/* General styles */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: var(--bg);
  font-family: var(--font);
}

a {
  text-decoration: none;
}
bold{
  font-family: var(--font-bold);
}
#root{
  overflow: hidden;
}
:root{
  --green: #8D9F8F;
  --light-gray: #C8CECE;
  --gray: #919392;
  --dark-gray: #5C5E5D;
  --text-gray: #515251;
  --bg: #EBF2F2;
  --beige: #E2D6C6;
  --orange: #D59567;
  --font-title: 'Antic', serif;
  --font: 'IntervogueSoftRegular', serif;
  --font-bold: 'IntervogueSoftMedium', serif;
  --topbar-mobile: 70px;
  --topbar-desktop: 130px
}

/* MOBILE FIRST  */

/* TOP BAR  */

.top-bar{
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--topbar-mobile);
  background: var(--green);
}

/* NAV DESKTOP */

.top-bar__logo, .top-bar__desktop-nav{
  display: none;
}
.top-bar__mobile{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mobile__hamburger{
  width: 20px;
  height: 20px;
  margin: 0 25px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.hamburger__line{
  width: 100%;
  height: 2px;
  background: white;
  transition: 0.3s;
}
.mobile__checkbox{
  width: 20px;
  height: 20px;
  margin: 0 -20px 0 0;
  opacity: 0;
  z-index: 101;
  cursor: pointer;
}
.mobile__hamburger.active .hamburger__line-top{
  transform: translate(0, 9px) rotate(45deg);
}
.mobile__hamburger.active .hamburger__line-mid{
  opacity: 0;
}
.mobile__hamburger.active .hamburger__line-bottom {
  transform: translate(0, -9px) rotate(-45deg);
}

/* MOBILE MENU */

.mobile__menu{
  width: 100vw;
  height: 100vh;
  position: fixed;
  gap: 15px;
  top:0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--green);
  transform: translateX(100%);
  transition: 0.3s;
  z-index: 99;
}
.mobile__menu.active{
  transform: translateX(0);
}
.menu__cross{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 100
}
.menu__mobile-nav{
  transform: scaleY(1.1);
  list-style: none;
  letter-spacing: 2px;
  text-align: center;
  font-family: var(--font-title);
}
.mobile-nav__item{
  margin: 0 0 20px 0;
}
.mobile-nav__item:last-of-type{
  margin: 0 0 25px 0;
}
.menu__mobile-nav a{
  color: #fff;
  font-size: 23px;
  transition: 0.3s color;
}
.menu__mobile-nav a:hover{
  color: var(--dark-gray)
}
.menu__shape{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--beige);
  margin: 0 0 20px 0;
}
.menu__logo{
  text-align: center;
  font-family: var(--font-title);
  font-weight: 300;
  font-size: 43px;
  color: #fff;
  transform: scaleY(1.1);
  letter-spacing: 2px;
  margin: 0 auto;
}
.desktop-nav__offer-menu{
  display: none;
}

/* MAIN PAGE - NATALIA*/

.hero__shape-right, .hero__shape-left, .hero__logo{
  display: none;
}
.hero{
  width: 100%;
  padding-top: var(--topbar-mobile);
}

.mobile-hero{
  width: 100%;
}
.mobile-hero img{
  width: 100%;
}

.hero__intro{
  position: absolute;
  left: 20px;
  top: calc(var(--topbar-mobile) + 260px);
  line-height: 1;
  display: none;
}
.intro__name {
  font-size: 34px;
}
.intro__surname{
  font-size: 39px;
}
.intro__name, .intro__surname{
  color: white;
  font-weight: 300;
  font-family: var(--font-title);
  transform: scaleY(1.1);
  text-transform: initial;
}
.intro__name::after{
  content: '';
  position: absolute;
  width: 50px;
  height: 1px;
  background: white;
  transform: translate(-76px, 35px);
}
.hero__info{
  margin: 20px 0 0 0;
}
.info-right, .info-left{
  margin: 0 10px ;
  text-align: center;
  font-size: 19px;
  line-height: 1.35;
  color: var(--text-gray)
}
.info-left{
  font-family: var(--font-bold);
}
.info-right{
  margin-bottom: 70px;
}
.info-shape{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* background: var(--green); */
  margin: 20px auto;
}
.info-shape img{
  width: 100%;
}
.hero__question{
  display: none;
}
.hero__gallery, .hero__recemendations{
  display: flex;
  margin:40px 0 0 0;
  background: var(--light-gray);
}
.gallery-container__photos, .recomendations-container__photos{
  width: 100%;
}

.gallery__header h3, .recomendations__header h3{
  font-weight: 300;
  color: var(--gray);
}

.swiper-container-horizontal > .swiper-scrollbar{
  bottom: 33px !important;
}
.swiper-scrollbar{
  margin: 0 0 -40px 0 !important;
  height: 10px !important;
  background: var(--gray) !important;
}
.swiper-scrollbar-drag {
  background: var(--orange) !important;

}
.swiper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide a, .swiper-slide a img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.swiper-slide a{
  padding: 0 20px;
}

.swiper-container{
  overflow: initial !important;
}
.hero__gallery, .hero__recemendations{
  flex-direction: column;
}
.gallery__gallery-container, .recomendations__recomendations-container{
  padding: 20px 0;
  margin: 0;
  min-width: 0;
}
.gallery__header , .recomendations__header{
  transform: none ;
  font-size: 24px ;
  z-index: 80;
  background: var(--light-gray);
  padding: 8px ;
  height: auto ;
  display: flex ;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--gray);
  border-right: none;

}
.gallery__header::after, .recomendations__header::after{
  content: none;
}
.hero__recomendations .swiper-wrapper{
  margin: 0 0 0 20px
}
.recomendation__container{
  background: var(--bg) !important;
  margin: 0 20px;
  color: var(--text-gray);
}
.recomendation{
  overflow-x: hidden;
  height: 250px;
  width: 90%;
  font-size: 18px;
  padding: 0 11px 8px 8px;
  margin: 10px 0 0 20px;
  line-height: 1.3;
}
.recomendation bold{
  transition: 0.3s color;
  color: var(--text-gray);
  padding: 7px 7px 7px 0;
}
.recomendation:hover bold{
  color: var(--green);
}
.recomendation__name {
  font-size: 21px !important;
  margin: 10px auto 25px 25px !important;
}
.recomendation__subtitle{
  margin: -17px 20px 10px 24px;
  font-size: 15px;
  min-height: 36px;
}
.recomendation__subtitle-small{
  font-size: 14px;
}
.recomendation::-webkit-scrollbar {
  width: 10px;
}
.recomendation::-webkit-scrollbar-track {
  background: var(--gray);
  border-radius: 10px;
}
.recomendation::-webkit-scrollbar-thumb {
  background: var(--text-gray);
  border-radius: 10px;
}
.hero__recemendations .swiper-slide{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swiper-slide h6{
  margin: 10px auto 20px 25px;
  font-size: 25px;
}
/* ABOUT */

.about{
  padding-top: var(--topbar-mobile)
}
.about__about-section-first{
  margin-top: 20px;
}

.about__section-first__title{
  color:var(--beige);
  font-size: 45px;
  font-weight: 300;
  font-family: var(--font-title);
  display: flex;
  justify-content:flex-end;
  margin: 0 10px -12px 0;
}
.about__section-first__container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container__img, .container__shape--img, .about__section-third__img{
  display: none;
}
.container__intro{
  position: relative;
  z-index: -2;
  background: var(--beige);
}
.intro__header{
  color: var(--text-gray);
  font-weight: 400;
  font-size: 35px;
  padding: 20px 0 0 20px;
  font-family: var(--font-title);
}
.intro__text{
  padding: 0 20px 20px 20px;
}
.container__description{
  display: flex;
}
.description__text, .about__section-second__mentor, .intro__text, .about__section-second__header, .buisness__text, .text__text-up, .text__text-down{
  font-size: 17px;
  color: var(--text-gray);
  line-height: 1.3;
}
.description__text{
  font-family: var(--font-bold);
  padding: 20px 5px 0 20px;
}
.description__img{
  width: auto;
  height: 368px;
  margin-top: -20px;
}
.container__description img{
  height: 100%;
}
.container__dot{
  width: 40px;
  height: 40px;
  background: var(--green);
  border-radius: 50%;
  margin: 0 0 40px 0;
}
.about__section-second__buisnesses{
  position: relative;
}
.about__section-second__mentor{
  padding: 0 20px 10px 20px;
}
.mentor__link a, .buisness__link a{
  color: var(--text-gray);
  font-style: italic;
  border-bottom: 1px solid var(--text-gray);
  padding-bottom: 5px;
  margin: 0 0 0 20px;
  transition: 0.3s color, border ease-in-out;
  cursor: pointer;
}
.mentor__link a{
  margin: 0;
}
.mentor__link a:hover, .buisness__link a:hover{
  color: var(--green);
  border-bottom: 1px solid var(--green);
}
.mentor__link img, .buisness__link img{
  width: 22px;
  height: 22px;
  position: relative;
  top: 6px;
  transition: 0.3s transform ease-in-out;
}
.mentor__link a:hover ~ img, .buisness__link a:hover ~ img{
  transform: translateX(5px);
}
.about__section-second__header{
  padding: 60px 20px 10px 20px;
  text-align: center;
}
.buisnesses__buisness{
  margin: 30px 0 0 0;
  padding: 0 10px 0 10px;
  display: grid;
  grid-template-columns: 120px 1fr;
}
.buisnesses__buisness:nth-of-type(2){
  grid-template-columns: 1fr 120px;
}
.buisnesses__buisness:nth-of-type(2) .buisness__logo{
  order: 2;
  margin: 0 20px 0 0;
}
.buisnesses__buisness:nth-of-type(2) .buisness__header{
  order: 1;
  color: var(--orange);
}
.buisnesses__buisness:nth-of-type(2) .buisness__text{
  order: 3;
  text-align: end;
}
.buisnesses__buisness:nth-of-type(2) .buisness__link{
  order: 4;
  justify-self: self-end;
}
.buisness__decoration{
  position: absolute;
  z-index: -1;
  width: 76px;
  top: 201px;
}
.buisness__logo {
  width: 100px;
  height: 100px;
  margin: 0 0 0 20px;
}

.buisness__logo img{
  width: 100%;
}
.buisness__header{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: var(--font-title);
  transform: scaleY(1.1);
  font-weight: 300;
  color: var(--green);
}
.buisness__text{
  margin:10px 20px 0 20px;
  grid-column: 1 / 3;
}
.buisness__link{
  grid-column: 1 / 3;
  padding: 5px 0;
}
.about__about-section-third{
  margin: 30px 0 0 0;
  overflow: hidden;
}
.text__text-up, .text__text-down{
  background: var(--beige);
  text-align: center;
}
.text__text-up{
  padding: 15px 20px 0 20px;
}
.text__text-down{
  padding: 15px 20px 15px 20px;
  font-family: var(--font-bold);
}
.about__section-third__header{
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  font-family: var(--font-title);
  color: var(--orange);
}
.about__section-third__img--mobile{
  width: 100vw;
  margin: 5px 0 -4px 0;
}

/* OFFER */
.offer{
  padding-top: var(--topbar-mobile);
}
.offer__offer-section {
  position: relative;
  display: grid;
  grid-template-columns: minmax(auto, 200px) 1fr;
  grid-template-rows: 1fr;
  font-size: 17px;
  line-height: 1.3;
} 
.offer-section__one-mobile-img1{
  position: absolute;
  left:0;
  z-index: -1;
  width: 190px;
}
.offer-section__one-mobile-img2{
  position: absolute;
  bottom: 100px;
  right: 40px;
  z-index: -1;
  width: 90px;
}
.offer-section__logo{
  order: 1;
  margin: 30px 20px 0 20px;
}
.offer-section__logo img{
  width: 100%;
}
.offer-section__header {
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-family: var(--font-title);
  font-weight: 300;
  text-align: center;
  line-height: 1;
  color: var(--orange);
  margin: 15px 10px 0 0
}
.offer__offer-section::nth-of-type(odd) .offer-section__header{
  padding: 0 10px 0 0 ;
}
.offer__offer-section::nth-of-type(even) .offer-section__header{
  padding: 0 0 0 10px;
}
.offer-section__p-one{
  margin-top: 30px;
}
.p-one__header{
  font-family: var(--font-bold);
  padding: 0 20px;
  text-align: center;
}
.p-one__text{
  display: none;
}
.p-one__text{
  padding: 5px 25px 10px 25px;
  text-align: center;
}
.p-one__text-mobile{
  display: initial;
  padding: 15px 25px 10px 25px;
  text-align: left;

}
.offer-section__p-one, .offer-section__p-two, .offer-section__p-three, .offer-section__p-four, .offer-section__dot{
  grid-column: 1 / 3;
  order: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-gray);
}
.offer-section__dot{
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 4px auto -4px auto;
  background: var(--orange);
}
.offer-section__p-two, .offer-section__p-three{
  padding: 20px;
}
.offer-section-three .offer-section__p-three{
  display: none;
}

.offer-section__p-four .p-four__header{
  color: var(--orange);
  padding: 20px 20px 10px 20px;
  font-size: 32px;
  font-family: var(--font-title);
}
.offer-section__p-four .p-four__text{
  font-family: var(--font-bold);
  text-align: center;
  padding: 0 20px 20px 20px;
  max-width: 400px;
}
.offer__interval{
  width: 100%;
  height: 40px;
  background: var(--beige);
  margin: 20px 0;
}
.interval-one, .offer-section__one-img, .offer-section__two-img1, .offer-section__three-img1, .offer-section__three-img2, .offer-section__four-img1, .offer-section__four-img2, .offer-section__five-img1 {
  display: none;
}
.interval-two{
  margin: 0 0 20px 0;
}
.interval-three{
  margin: 0;
}
.interval-four{
  margin: 20px 0 0 0;
}
.offer-section__two-mobile-img1{
  position: absolute;
  top: 174px;
  width: 45px;
  right: 0;
  z-index: -1;
}
.offer-section__two-mobile-img2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  z-index: -1;
}
.offer-section-two, .offer-section-four {
  grid-template-columns: 1fr minmax(auto, 200px);
}
.offer-section-two .offer-section__logo, .offer-section-four .offer-section__logo{
  grid-column: 2;
}
.offer-section-two .offer-section__header, .offer-section-four .offer-section__header{
  order: initial;
  margin: 15px 0 0 0;
  width: 100%;
  color: var(--green)
}
.offer-section-two .offer-section__header{
  padding: 0 0 0 10px;
}
.offer-section-two .p-one__text, .offer-section-four .p-one__text{
  text-align: left;
}
.offer-section-two .offer-section__dot, .offer-section-four .offer-section__dot{
  background: var(--green);
}
.offer-section-two .p-three__header{
  font-size: 18px;
  padding: 10px 49px 30px 50px;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--green);
}

.offer-section__three-mobile-img1 {
  position: absolute;
  right: 0;
  top: 243px;
  width: 77px;
  z-index: -1;
}
.offer-section__three-mobile-img2{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 130px;
  z-index: -1;
}

.offer-section__list{
  order: 3;
  margin: 10px auto 20px auto;
  padding: 0 20px;
  grid-column: 1 / 3;
  color: var(--text-gray);
}
.offer-section-three .p-one__header{
  font-family: var(--font-title);
  font-size: 32px;
  line-height: 1;
  padding: 5px 20px 20px 20px;
}
ul{
  list-style: none;
}
.offer-section__list li::before {
  content: "";
  background: black;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px -1px 0;
}
.offer-section-three .offer-section__p-three{
  font-family: var(--font-bold);
}

.offer-section__four-mobile-img1{
  position: absolute;
  z-index: -1;
  width: 55px;
  left: 0;
  top: 0;
}
.offer-section-four .offer-section__header{
  font-size: 24px;
  padding: 0 0 0 10px;
}
.offer-section-four .p-one__header{
  font-size: 20px;
  margin: 0 0 20px 0;
}
.offer-section-four .offer-section__p-two {
  text-align: center;
  padding: 10px 20px 0 20px;
}
.offer-section-four .p-three__header{
  font-family: var(--font-bold);
  text-align: center;
}
.offer-section__five-mobile-img1{
  position: absolute;
  z-index: -1;
  width: 140px;
  top: 0;
  left: 0;
}
.offer-section-five .offer-section__header{
  font-size: 24px;
  padding: 0 10px 0 0;
}
.offer-section-five .p-one__header{
  color: var(--orange);
  font-size: 22px
}
.offer-section-five .offer-section__p-two{
  text-align: center;
  font-family: var(--font-bold);
  padding: 20px;
}
.offer__projects{
  background: var(--beige);
  width: 100%;
  display: grid;
  grid-template-columns: 100px 100px;
  grid-gap: 10px;
  justify-content: center;
  padding: 20px;
}
.projects__project{
  width: 100px;
  border-radius: 50%;
}
.projects__project img{
  width: 100%;
  transition: 0.3s transform ease-in-out;
}
.projects__project img:hover{
  transform: rotate(25deg);
}

/* CONTACT */

.contact-shape{
  display: none;
}

.contact{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--dark-gray);
  padding-top: var(--topbar-mobile);
}
.mobile-contact-shape{
  width: 100%;
  min-height: 350px;
  max-height: 350px;
  position: absolute;
  margin-top: var(--topbar-mobile);
  z-index: -1;
}
.contact__info-title{
  font-family: var(--font-title);
  font-weight: 400;
  font-size: 36px;
  position: relative;
  margin: 20px 0;
}
.contact__info-title::after{
  content: '';
  height: 0.04rem;
  width: 150px;
  background: var(--dark-gray);
  position: absolute;
  bottom: 0;
  transform: translate(-150px, -1px);
}
.contact__map{
  order: 2;
  width: 330px;
  height: 330px;
  border: 15px solid var(--beige);
  margin: 0 0 10px 0;
}
.contact__info{
  order: 1;
  margin: 0 0 50px 0;
  text-align: center;
}
.contact__info-p{
  font-size: 18px;
}
.contact__info-p:nth-of-type(5){
  margin: 0;
}
.contact__info-shape{
  width: 15px;
  height: 15px;
  margin: 0 0 10px 0;
  border-radius: 50%;
  background: var(--green);
}
.contact__social{
  order: 3;
  position: unset;
  margin: 20px 0 140px 0;
}

.contact__social a img{
  width: 100px;
  border-radius: 50%;
  transition: transform 0.3s;
}
.contact__social a img:hover{
  transform: rotate(30deg);
}
.contact__info-shape{
  margin: 10px auto;
}

@media (min-width:600px){
  .hero__intro {
    left: 50px;
    top: calc(var(--topbar-mobile) + 340px);
  }
  .intro__name {
    font-size: 52px;
  }
  .intro__name::after {
    width: 77px;
    height: 2px;
    transform: translate(-119px, 54px);
  }
  .intro__surname {
    font-size: 57px;
  }
}

/* DESKTOP */

@media (min-width:850px){
  
 /* TOPBAR */

  .mobile__checkbox, .mobile__hamburger, .mobile-hero{
    display: none;
  }
  .top-bar{
    height: calc(var(--topbar-desktop) - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .top-bar__logo{
    display: inline;
    margin: 15px 0 0 0px;
    font-family: var(--font-title);
    font-size: 50px;
    color: white;
    letter-spacing: 5px;
    font-weight: 300;
    transform: scaleY(1.1);
  }
  .top-bar__desktop-nav{
    width: 100%;
    height: 40px;
    position: fixed;
    margin-top: calc(var(--topbar-desktop) - 40px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    list-style: none;
    background: var(--light-gray);
  }
  .desktop-nav__offer-menu{
    display: grid;
    width: 100%;
    height: 307px;
    background: var(--light-gray);
    position: fixed;
    top:39px;
    grid-template-columns: 410px 185px;
    grid-template-rows: 261px;
    justify-content: center;
    border-top: 1px solid white;
    transform: translateY(-216px);
    transition: 0.5s transform ease-in-out;
    z-index: 10;
  }
  .offer-menu__list::after{
    content: '';
    position: absolute;
    width: 1px;
    top: 55px;
    right: 30px;
    height: 162px;
    background: white;
  }
  .offer-menu__list {
    position: relative;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .offer-menu__list ul li{
    margin: 15px 0 0 0;
  }
  .offer-menu__list ul li a, .offer-menu__link a{
    position: relative;
    color: var(--text-gray);
    transition: 0.3s color ease-in-out;
  }
  .offer-menu__list ul li a::before{
    content: '';
    position: absolute;
    top: 6px;
    left: -16px;
    width: 10px;
    height: 10px;
    background: var(--text-gray);
    border-radius: 50%;
  }
  .offer-menu__list ul li a:hover, .offer-menu__link a:hover{
    color: var(--green)
  }
  .offer-menu__projects-links{
    display: grid;
    grid-template-columns: 80px 80px;
    grid-template-rows: 80px;
    grid-gap: 5px;
    align-self: center;
    margin: 0 0 0 20px;
  }
  .offer-menu__projects-links a{
    width: 80px;
  }
  .offer-menu__projects-links a img{
    width: 100%;
  }
  .offer-menu__link{
    grid-column: 1 / 3;
    margin: 0 auto;
    font-size: 24px;
    color: var(--text-gray);
    font-family: var(--font-bold);
  }
  .offer-menu__link a::before, .offer-menu__link a::after{
    content: '';
    position: absolute;
    top: 7px;
    width: 12px;
    height: 12px;
    background: var(--text-gray);
    border-radius: 50%;
  }
  .offer-menu__link a::before{
    left: -20px
  }
  .offer-menu__link a::after{
    right: -20px
  }
  .desktop-nav__item{
    font-size: 21px;
    border-right: 1px solid var(--dark-gray);
    padding-right: 20px;
    margin: 0 0 0 0;
  }
  .desktop-nav__item:nth-of-type(3){
    margin: 0;
    padding: 0;
  }
  .desktop-nav__item:nth-of-type(3) a{
    padding: 8px 20px 8px 0;

  }
  .desktop-nav__item:last-of-type{
    border-right: none;
  }
  .desktop-nav__item a{
    color:var(--dark-gray);
    transition: color 0.3s;
  }
  .desktop-nav__item a:hover{
    color: var(--green);
  }
  .desktop-nav__item:nth-of-type(5){
    border: none;  
  }  /* MAIN PAGE - NATALIA */
  .hero{
    padding-top: var(--topbar-desktop);
  }
  .hero__shape-right{
    display: block;
    position: absolute;
    z-index: -1;
  }
  .hero__shape-right{
    right: 0;
    top: var(--topbar-desktop);
    max-width: 400px
  }
  .hero__intro{
    position: relative;
    margin: 35px 0 0 60px;
    left: unset;
    top: unset;
    line-height:1;
    display: block;
  }
  .intro__name, .intro__surname {
    color: var(--gray);
    font-weight: 300;
    text-transform: uppercase;
  }
  .intro__name {
    font-size: 60px;
  }
  .intro__surname {
    font-size: 70px;
    margin: 0 0 0 45px;
  }
  .intro__name::after {
    width: 98px;
    height: 1px;
    transform: translate(43px, 53px);
    background: var(--gray);
  }
  .hero__info {
    margin: 50px 0 0 40px;
    width: 400px;
  }
  .info-left{
    margin: 0 50px 40px 0;
    text-align: left;

  }
  .info-right{
    margin: 0 0 0 50px;
    text-align: right;
  }
  .info-shape{
    display: none
  }

  /* ABOUT */

  .about {
    padding-top: var(--topbar-desktop);
  }
  .about__section-first__container {
    position: relative;
    flex-direction: initial;
    background: var(--beige);
  }
  .description__img, .container__dot, .about__section-third__img--mobile, .buisness__decoration{
    display: none;
  }
  .container__img{
    display: block;
    width: 200px;
    position: absolute;
    height: auto;
    top: -70px;
    left: 0;
  }
  .container__shape--img{
    display: block;
    position: absolute;
    top:-61px;
    right: 0;
    width: 200px;
  }
  .container__img img, .container__shape--img img{
    width: 100%;
  }
  .container__intro {
    z-index: 0;
    background: initial;
    padding: 30px 0 70px 225px;
    text-align: right;
}
  .intro__header, .intro__text{
    padding: 0;
  }
  .intro__header{
    font-size: 40px;
  }
  .intro__text{
    font-size: 18px;
  }
  .container__description{
    padding: 130px 40px 35px 0;
  }
  .about__section-first__title{
    justify-content:end;
      margin: 0 10px -12px 171px;
  }
  .about__section-second__mentor{
    padding: 100px 20px 0 20px;
    margin: 0 auto;
    max-width: 1022px;
    text-align: justify;
  }
  .mentor__link{
    margin: 10px 0 0 0;
  }
  .about__section-second__buisnesses {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .buisnesses__buisness {
    grid-template-columns: 220px minmax(auto, 806px);
    margin: 70px 0 0
  }
  .buisness__logo {
    width: 200px;
    height: 200px;
    grid-row: 1 / 4;
  }
  .buisness__link, .buisness__text{
    grid-column: initial;
    text-align: justify;
  }
  .buisness__header {
    justify-content: initial;
    font-size: 44px;
    padding: 0 0 0 19px;
    font-family: var(--font-title);
    font-weight: 300;
    transform: scaleY(1.1);

  }
  .buisnesses__buisness:nth-of-type(2){
    grid-template-columns: 1fr 220px;
    grid-template-columns: minmax(auto, 806px) 220px ;
  }
  .buisnesses__buisness:nth-of-type(2) .buisness__logo{
    order: 2;
    grid-column: 2;
    margin: 0 20px 0 0;
  }
  .buisnesses__buisness:nth-of-type(2) .buisness__header{
    justify-content: flex-end;
    padding: 0 18px 0 0;
  }
  .about__about-section-third {
    margin: 200px 0 0 0;
    overflow: initial;
    position: relative;
  }
  .about__section-third__text{
    background: var(--beige);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text__text-up {
    padding: 20px 0 0 0;
    max-width: 461px;
  }
  .text__text-down {
    padding: 20px 0;
    max-width: 436px;
  }
  .about__section-third__header {
    padding: 20px 0 80px 0;
    margin: 0 200px 0 0;
    font-size: 36px;
  }
  .about__section-third__img {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 380px;
  }
  .text__text-up, .text__text-down, .about__section-third__header{
    margin: 0 380px 0 0;
  }
  /* OFFERT */
  .offer {
    padding-top: var(--topbar-desktop);
  }
  /* CONTACT */

  .contact{
    padding-top: var(--topbar-desktop);
  }


}


@media (min-width:1050px){
  .swiper-slide a{
    padding: 0;
  }
  .hero__recemendations .recomendation__container{
    margin: 0;
  }
  .hero__logo{
    display: block;
    width: 200px;
    position: absolute;
    top: calc(var(--topbar-desktop)  + 20px);
    left:20px;
  }
  .hero__logo img{
    width: 100%;
  }
  .hero__question{
    display: block;
    margin: 110px 0 0 9%;
    font-size: 34px;
    font-family: var(--font-title);
    color: var(--text-gray);
  }
  .hero__gallery, .hero__recemendations{
    flex-direction: row !important;
  }
  .gallery__header, .recomendations__header{
    font-size: 47px;
    background: var(--light-gray);
    align-items: center;
    position: relative;
    width: 308px;
    height: 373px;
    margin: 0px 30px 0 0px;
    padding: 0 ;
    border-right: 2px solid var(--gray);
    border-bottom: none;
  }
  .recomendations__header{
    height: 488px !important;
    padding: 60px 0 60px 0;
    width: 229px !important;
  }

  .gallery__header h3, .recomendations__header h3{
    transform: rotate(-90deg);
  }
  .gallery__gallery-container, .recomendations__recomendations-container{
    padding: 60px 0;
  }

  .swiper-container-horizontal > .swiper-scrollbar{
    bottom: 10px !important;
  }
.hero__gallery .swiper-wrapper:hover > .swiper-slide{
  opacity: 0.5;
  transform: scale(1);

  transition: opacity 0.3s ease-in-out ;
  transition: transform 0.3s ease-in-out ;
}
.swiper-slide {
  transition: opacity 0.3s ease-in-out ;
  transition: transform 0.3s ease-in-out ;
}

.hero__gallery .swiper-wrapper:hover > .swiper-slide:hover{
  transform: scale(1.2);
  opacity: 1;
}

   /* MAIN PAGE - NATALIA */

  .hero__shape-left{
    display: block;
    position: absolute;
    z-index: -1;
  }
  .hero__info {
    margin: 50px 0 0 23%;
  }
  .hero__intro{
    margin: 35px 0 0 21%;
  }
  .hero__shape-right{
    max-width: 450px
  }

  /* ABOUT */

  .about__section-third__img {
    width: 400px;
  }
  .about__section-third__header {
    font-size: 38px;
  }
  .about__about-section-third {
    margin: 220px 0 0 0;
  }
  .text__text-up, .text__text-down, .about__section-third__header {
    margin: 0 200px 0 0;
  }


  /* Offer */
  #live-coaching, #coaching-biznesowy, #warsztaty-w-poszukiwaniu-pewnosci-siebie,#warsztaty-job-crafting, #warsztaty-szczesliwe-rodzicielstwo {
    display: block;
  }
  .offer{
    padding-top: var(--topbar-desktop)
  }
  .mobile-img{
    display: none;
  }
  .offer-section__one-img{
    display: block;
    position: absolute;
    z-index: -1;
    top:-20px;
    right: 0;
    width: 184px;
  }
  .offer__offer-section {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    font-size: 20px;
    justify-content: center;
    margin: 0 0 150px 0;
  }
  .offer-section-five .offer-section__header, .offer-section-four .offer-section__header {
    font-size: 40px !important;
    top:-89px;
  }
  .offer-section__header {
    order: initial;
    grid-column: 1 / 6;
    position: absolute;
    left: 50%;
    top: -118px;
    width: 100%;
    transform: translateX(-50%) scaleY(1.1);
    color: #c6bbac !important;
    font-size: 50px !important;
    font-weight: 300;
  }
  .offer-section__logo {
    order: initial;
    max-width: 300px;
    min-width: 300px;
    margin: 20px auto 0 auto;
  }
  .offer-section-one .offer-section__logo{
    grid-column: 2;
    padding: 0 20px 0 0;

  }
  .offer-section__p-one, .offer-section__p-two, .offer-section__p-three, .offer-section__p-four, .offer-section__dot {
    grid-column: initial;
    order: initial;
    grid-column: initial;
    align-items: initial;
    justify-content: initial;
  }

  .offer-section-one{
    grid-column: 2 / 4;
  }
  .offer-section-one .offer-section__p-one{
    grid-column: 3 / 5;
    padding: 20px 0 0 20px;
    max-width: 511px;
    margin: 30px 0 0 -20px;
  }
  .offer-section__p-one{
    grid-column: 2 / 4;
    padding: 0 0 0 40px;
  }
  .offer-section__p-two{
    padding: 30px 70px 0 15px !important;
  }
  .offer-section-one .offer-section__p-two, .offer-section-one .offer-section__p-three{
    grid-column: 1 / 4;
    min-width: 634px;
    justify-self: flex-end;
  }
  .offer-section__p-two, .offer-section__p-three {
    padding: 0 70px 0 15px;
    grid-column: 1 / 4;
    text-align: end;
  }
  .offer-section__dot{
    display: none;
  }
  .offer-section__p-four{
    grid-column: 4 / 6;
    min-width: 300px;
    max-width: 392px;
    margin: 0 0 0 -50px;
  }
  .p-one__header, .p-one__text, .p-four__text, .p-four__header{
    padding: 0 !important;
    text-align: left !important;
  }
  .p-one__text{
    display: initial;
  }
  .p-one__text-mobile{
    display: none;
  }
  .interval-one{
    display: block;
    margin: 100px 0 20px 0;
  }

    /* second section offer  */

  .offer-section-two .offer-section__logo{
    order: 3;
    margin: 20px -123px 0 auto;
  }
  .offer-section-two .offer-section__p-one{
    order: 1;
    grid-column: 1 / 3;
    margin: 30px -120px 0 0;
    padding: 0;
    justify-self: flex-end;
  }
  .offer-section-two .p-one__header, .offer-section-two .p-one__text{
    text-align: right !important;
    max-width: 567px;
  }
  .offer-section-two .offer-section__p-two{
    text-align: left;
    order: 2;
    grid-column: 3/5;
    grid-row: 2;
    padding: 0 0 65px 0!important;
    margin: -160px -150px 0 150px;
    max-width: 511px;
  }
  .offer-section-two .offer-section__p-three{
    order: 2;
    grid-column: 3 / 5;
    grid-row: 3;
    padding: 0 0 0 0;
    margin: 0 -130px 0 150px;
  }
  .offer-section-two .p-three__header {
    max-width: 505px;
    padding: 20px 0 0 0;
    margin: -65px 0 0 0;
    text-align: left;
    font-size: 26px;
  }
  .offer-section__two-img1{
    position: absolute;
    display: block;
    width: 245px;
    left: 0;
    top: 205px;
    z-index: -1;
  }

  /* third section offer  */

  .offer-section-three .offer-section__logo{
    order: 2;
  }
  .offer-section-three .offer-section__p-one{
    order: 1;
    align-items: flex-end;
  }
  .offer-section-three .p-one__text{
    padding: 0 20px 0 0 !important;
  }
  .offer-section-three .p-one__header{
    text-align: right !important;
    padding: 20px 32px 60px 0 !important;
    max-width: 422px;
  }
  .offer-section-three .offer-section__p-two{
    order: 3;
    padding: 0 0 0 0 !important;
    margin: -40px 0 0 0;
    display: flex;
    max-width: 700px;
    justify-self: end;
  }
  .offer-section-three .offer-section__list{
    order: 4;
    grid-column: 4;
    margin: 115px 0 0 0;
    padding: 0 0 0 30px;
  }
  .offer-section-three .offer-section__p-three{
    order: 5;
    grid-column: 2/5;
    padding: 36px 0 0 0;
    max-width: 761px;
    text-align: left;
    margin: 0 auto;
    position: relative;
  }
  .offer-section-three .offer-section__p-three {
    display: initial;
  }
  .offer-section__three-img1{
    position: absolute;
    display: block;
    top: -20px;
    right: 0;
    width: 300px;
    z-index: -1;
  }
  .offer-section__three-img2{
    position: absolute;
    display: block;
    bottom: -20px;
    left: -68px;
    width: 150px;
    z-index: -1;
  }

  /* fourth section offer */

  .offer-section-four{
    padding: 40px 0 0 0;
  }
  .offer-section-four .offer-section__p-one{
    order: 2;
    grid-column: 3 / 5;
    color: var(--green);
    margin-top: 10px;
  }
  .offer-section-four .p-one__header{
    font-size: 29px;
    min-width: 422px;
  }
  .offer-section-four .offer-section__list{
    order: 3;
    margin: -290px 0px 0 20px;
    grid-column: 3 / 5;
    padding: 30px 20px 0 20px;
    min-width: 500px;
  }
  .offer-section-four .offer-section__p-two{
    order: 4;
    grid-column: 2;
    grid-row: 2;
    padding: 0 !important;
    margin: 20px 0 0 0;
    font-size: 20px;
}
  .offer-section-four .offer-section__p-three{
    order: 5;
    grid-column: 2;
    grid-row: 2;
    margin: 72px 0 0 0;
    padding: 20px 0;
    font-size: 20px;
  }
  .offer-section__four-img1{
    position: absolute;
    display: block;
    z-index: -1;
    top:0;
    left: 0;
    width: 260px;
  }
  .offer-section__four-img2{
    position: absolute;
    display: block;
    z-index: -1;
    top:0;
    right: 0;
    width: 80px;
  }
  /* fifth section offer */
  .offer-section-five{
    margin: 0 0 60px 0 ;
  }
  .offer-section-five .offer-section__logo{
    order: 3;
    margin: -50px auto 0 auto;
    padding: 0 0 150px 0;
  }
  .offer-section-five .offer-section__p-one{
    order: 1;
    margin: 70px 0 0 auto;
    padding: 0 0 0 30px;
    grid-column: 1 /4;
    min-width: 594px;
}
  .offer-section-five .p-one__header{
    font-size: 29px;
  }
  .offer-section-five .offer-section__list{
    order: 2;
    grid-column: 1 /4;
    padding: 0 0 0 30px;
    margin: 20px 0 20px auto;
  }
  .offer-section-five .offer-section__p-two{
    order: 4;
    grid-column: 4;
    padding: 10px 0 0 0 !important;
    font-weight: 300;
    margin: -135px 0 70px 0;
    font-size: 20px;
    min-width: 397px;
  }
  .offer-section__five-img1{
    position: absolute;
    display: block;
    top:0;
    right: 0;
    width: 350px;
    z-index: -1;
  }
  .offer__projects{
    grid-template-columns: minmax(auto, 200px) minmax(auto, 200px) minmax(auto, 200px) minmax(auto, 200px);
    justify-items: center;
    padding: 70px 0;
  }
  .projects__project{
    max-width: 200px;
    width: initial;
  }
  /* CONTACT */

  .contact{
    flex-direction: row;
    padding-top: calc(var(--topbar-desktop) + 20px);
  }
  .contact__map {
    order: 1;
    margin: 0 50px 0 0;
  }
  .contact__info{
    padding: 0 0 120px 0;
    margin: 0px 50px 0 0;
    text-align: initial;
  }
  .contact__info-shape {
    margin: 10px 0;
    background: var(--gray) !important;
}
  .contact__social{
    margin: 225px 0 0 40px;
  }
  .contact-shape{
    display: block;
    position: absolute;
    bottom: 0;
    z-index: -1;
    height: 60%;
  }
  .mobile-contact-shape{
    display: none;
  }
}
@media (min-width:1050px) and (max-width:1160px){
  .gallery__header, .recomendations__header{
    height: 461px !important;
}
}
@media (min-width: 1200px){
  .hero__logo{
    top: calc(var(--topbar-desktop)  + 40px);
    left: 40px;
  }
  .hero__shape-right {
    max-width: 550px;
}
}
@media (min-width: 1330px){
  /* HERO */
  
  /* ABOUT */

  .about__section-third__header {
    padding: 20px 0 50px 0;
}

  /* OFFER */
  .offer-section__one-img{
    width: 300px;
  }
  .offer-section__two-img1 {
    width: 330px;
    top: 105px
  }
  .offer-section__three-img1 {
  width: 400px;
  }
  .offer-section__four-img1 {
    width: 380px;
  }
  .offer-section__four-img2 {
    width: 160px;
  }
  .offer-section__five-img1 {
    width: 450px;
  }
}
@media (min-width: 1360px){
  .offer-section-five .offer-section__p-one {
    padding: 0 0 0 70px;
    min-width: 632px;
}
}
@media (min-width:1400px){
  .hero__shape-right{
    max-width: 650px;
  }
  .hero__gallery ,.hero__recemendations{
    margin: 31px 0 0 0;
  }

  /* CONTACT */

  .contact__map {
    width: 400px;
    height: 400px;
    margin: 58px 50px 0 0;
  }
  .contact__info-p{
    font-size: 20px;
  }
  .contact__social {
    margin: 348px 0 0 0;
  }

  /* OFFER */
  .offer-section__one-img{
    width: 380px;
  }

  /* ABOUT */
  .container__img, .container__shape--img{
  width: 300px;
  top:-100px
  }
  .about__section-first__title{
    margin: 0 10px -12px 264px;
    font-size: 50px;
    font-family: var(--font-title);
    transform: scaleY(1.1);
  }
  .container__shape--img {
    top: -69px;
  }
  .container__intro {
    max-width: 919px;
    padding: 30px 0 70px 315px;
  }
  .container__description {
    max-width: 512px;
    padding: 220px 70px 55px 0;
  }
  .intro__header {
    font-size: 50px;
  }
  .intro__text, .about__section-second__mentor, .about__section-second__header, .buisness__text, .text__text-down, .text__text-up {
    font-size: 20px;
  }
  .description__text {
    font-size: 18px;
  }
  .about__section-third__img {
    width: 514px;
  }
  .about__section-third__header {
    font-size: 40px;
    font-family: var(--font-title);
  }
  .about__about-section-third {
    margin: 280px 0 0 0;
  }
}
@media (min-width:1550px){
  .intro__name {
    font-size: 70px;
  }
  .intro__surname {
    font-size: 80px;
    margin: 0px 0 0 50px;
  }
  .hero__info{
    display: flex;
    width: 800px;
    margin: 70px 0 0 15%
}
  .intro__name::after {
    width: 115px;
    transform: translate(41px, 58px);
  }
  .hero__question{
    margin: 70px 0 0 15%;

  }
  .info-left{
    text-align: right;
    margin: -30px 10px 0 0;
    max-width: 300px;
  }
  .info-right{
    text-align: left;
    margin: 28px 0 0 10px;
    max-width: 350px;
  }
  .hero__gallery{
    margin: 156px 0 0 0;
  }
  .hero__recemendations{
    margin: 80px 0 0 0;
  }
  /* OFFER */

  .offer-section__one-img{
    width: 420px;
  }

  /* ABOUT */

  .about__section-third__img {
    width: 614px;
  }
  .about__section-third__header {
    font-size: 42px;
  }
  .about__about-section-third {
    margin: 320px 0 0 0;
  }

}

@media (min-width:1650px){
  .hero__shape-right{
    max-width: unset;
  }
  .hero__gallery {
    margin: 298px 0 0 0;
  }
  .hero__recemendations{
    margin: 106px 0 0 0;
  }
  .intro__name {
    font-size: 80px;
  }
  .intro__surname {
    font-size: 90px;
    margin: 0px 0 0 56px;
  }
  .intro__name::after {
    width: 127px;
    transform: translate(40px, 67px);
  }
  .info-right, .info-left{
    font-size: 20px;
  }
}
